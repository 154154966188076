import React from 'react';

export const DocumentIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect fill="currentColor" height="24" rx="3" width="24" />
    <g fill="#fff">
      <path d="M4.25,2.06A1.91,1.91,0,0,0,2.84,3.52c-.06.27-.07,14.67,0,15a2,2,0,0,0,1,1.33c.32.16.47.17.59,0s.09-.35-.24-.5a1.46,1.46,0,0,1-.67-.65l-.11-.22V3.58l.09-.19a1.23,1.23,0,0,1,.62-.65l.26-.13H9.86c6,0,5.69,0,6.07.22a1.26,1.26,0,0,1,.48.62c.14.35.3.43.51.27s.1-.1.09-.27A1.89,1.89,0,0,0,15.58,2C15.26,2,4.52,2,4.25,2.06Z" />
      <path d="M6.66,3.4A1.16,1.16,0,0,0,6,4c-.08.16-.09.23-.09.77A1.62,1.62,0,0,0,6,5.52a1.1,1.1,0,0,0,.5.54l.2.1H13.2l.2-.1a1.06,1.06,0,0,0,.5-.54A1.54,1.54,0,0,0,14,4.78,1.7,1.7,0,0,0,13.9,4a1.15,1.15,0,0,0-.48-.52l-.18-.1H10C8.22,3.38,6.71,3.39,6.66,3.4ZM13.17,4c.2.12.23.21.23.73s-.05.67-.27.77A17.79,17.79,0,0,1,10,5.6c-3.32,0-3.24,0-3.39-.22s-.08-.2-.09-.53c0-.5,0-.68.23-.81L6.83,4H9.94C13,4,13.05,4,13.17,4Z" />
      <path d="M16.61,4.67l-.1.1V15.38l-1.87,1.88c-1,1-1.91,1.94-2,2l-.09.15H9.11c-2.45,0-3.53,0-3.62,0a.28.28,0,0,0-.12.47c.08.07.13.07,3.54.07,1.9,0,3.45,0,3.45,0s0,.21-.12.43a1.76,1.76,0,0,0-.1.62,1,1,0,0,0,.91.9,2.06,2.06,0,0,0,.82-.16,6.58,6.58,0,0,0,.86-.3A39.09,39.09,0,0,0,18,18.38a38.77,38.77,0,0,0,3.12-3.23,1.1,1.1,0,0,0,.09-.53c0-.46-.08-.62-.55-1.1s-.68-.6-1.14-.59-.59,0-1.58,1l-.85.84v-5c0-4.71,0-5-.06-5.09a.42.42,0,0,0-.2-.1A.19.19,0,0,0,16.61,4.67Zm3.17,8.88a2.67,2.67,0,0,1,.79.78c.17.36.07.6-.46,1.12l-.38.38L19,15.12l-.72-.72.41-.4C19.23,13.49,19.43,13.4,19.78,13.55Zm-1.15,2,.69.69-.35.35-.35.36-.71-.72-.72-.71.33-.33a2,2,0,0,1,.37-.33A7.44,7.44,0,0,1,18.63,15.55ZM16.5,19.07c-.93.93-1.74,1.73-1.79,1.76l-.1.08-.7-.7-.7-.7L15,17.73,16.76,16l.71.71.72.72Zm-2.79,2.2c-.49.15-.61.17-.75.1a.48.48,0,0,1-.24-.36,3.39,3.39,0,0,1,.12-.49l.12-.43.55.54.53.54Z" />
      <path d="M15.8,17.93c-.48.47-.54.55-.54.67a.27.27,0,0,0,.28.28c.11,0,.19-.06.67-.54s.53-.56.53-.66a.3.3,0,0,0-.29-.28C16.36,17.4,16.24,17.49,15.8,17.93Z" />
      <path d="M6.62,8l-.8.8-.28-.28c-.23-.22-.3-.28-.4-.28a.26.26,0,0,0-.28.28c0,.12,0,.18.42.56s.45.42.54.42.26-.14,1.06-.94,1-1,1-1.08a.27.27,0,0,0-.28-.28C7.44,7.16,7.33,7.25,6.62,8Z" />
      <path d="M9.37,8.31a.27.27,0,0,0-.09.45c.08.08.13.08,2.59.08h2.52l.08-.1a.24.24,0,0,0,0-.36l-.09-.11H11.93A17.6,17.6,0,0,0,9.37,8.31Z" />
      <path d="M6,11a1.39,1.39,0,0,0-.8.72,1.54,1.54,0,0,0,0,1,1.28,1.28,0,0,0,1.2.81,1.25,1.25,0,0,0,1.16-.75,1.3,1.3,0,0,0,0-1.06,1.44,1.44,0,0,0-.65-.64A1.69,1.69,0,0,0,6,11Zm.69.61a.69.69,0,1,1-1,.38A.69.69,0,0,1,6.66,11.65Z" />
      <path d="M9.3,12.06a.26.26,0,0,0,0,.4l.09.09h5l.1-.1a.31.31,0,0,0,.09-.15.34.34,0,0,0-.18-.29c-.07,0-.83,0-2.54,0H9.39Z" />
      <path d="M6,14.74a1.45,1.45,0,0,0-.84.73,1.6,1.6,0,0,0,0,1,1.53,1.53,0,0,0,.62.64.87.87,0,0,0,.57.11.86.86,0,0,0,.56-.11,1.47,1.47,0,0,0,.62-.64,1.43,1.43,0,0,0,0-1,1.55,1.55,0,0,0-.62-.65,1,1,0,0,0-.5-.1A1.47,1.47,0,0,0,6,14.74Zm.73.65a.69.69,0,0,1,.12,1.05.66.66,0,0,1-.59.21.69.69,0,0,1-.58-.86.76.76,0,0,1,.37-.45A.89.89,0,0,1,6.73,15.39Z" />
      <path d="M9.3,15.77a.25.25,0,0,0,0,.4l.09.09h2.48c2.36,0,2.48,0,2.57-.07a.31.31,0,0,0,0-.44c-.09-.07-.21-.07-2.57-.07H9.39Z" />
    </g>
  </svg>
);
