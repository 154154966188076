import { theme } from '@basic';
import { ChakraProvider } from '@chakra-ui/react';
import { Layout } from '@components/layout';
import React from 'react';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'twin.macro';

interface Props {
  element: JSX.Element;
  props: Record<any, any>;
}

function Component({ element, props }: Props) {
  const { uri } = props;

  return (
    <>
      <GlobalStyles />
      <ChakraProvider theme={theme}>
        <Helmet>
          {/* <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${process.env.GATSBY_GTM_ID}');
              `,
            }}
          /> */}
          <meta
            content={process.env.GATSBY_GOOGLE_SITE_VERIFICATION}
            name="google-site-verification"
          />
        </Helmet>
        <Layout basePathname={`/${uri.split('/')[1]}`} fullPath={uri}>
          {element}
        </Layout>
      </ChakraProvider>
    </>
  );
}

export const wrapPageElement = Component;
