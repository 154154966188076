import { configureStore } from '@reduxjs/toolkit';
import { uiSlice } from './ui';
import { vendorScriptsSlice } from './vendorScripts';

export const store = configureStore({
  reducer: {
    scripts: vendorScriptsSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
