import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import { RootState } from '@redux/store';
import {
  SetDrawerNavState,
  setDrawerNavState as setDrawerNavStateAction,
  SetTheme,
  setTheme as setThemeAction,
} from '@redux/ui';
import { CloseIcon, DarkModeIcon, LightModeIcon, Logo } from '@svg';
import { Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  basePathname: string;
  isOpen: boolean;
  setDrawerNavState: SetDrawerNavState;
  setTheme: SetTheme;
  theme: string;
}

const Component = (props: Props) => {
  //
  const { isOpen, setDrawerNavState, setTheme, theme } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  const handleDarkMode = () => {
    if (colorMode === 'dark') {
      document.documentElement.classList.remove('dark');
      setTheme('light');
      toggleColorMode();
      return;
    }

    if (colorMode === 'light') {
      document.documentElement.classList.add('dark');
      setTheme('dark');
      toggleColorMode();
      return;
    }
  };

  const handleCloseMobileNav = () => {
    setDrawerNavState(false);
  };

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={handleCloseMobileNav}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          <div className="flex justify-between">
            <Logo className="w-auto h-8" />
            <button onClick={handleCloseMobileNav}>
              <CloseIcon />
            </button>
          </div>
        </DrawerHeader>
        <DrawerBody>
          <ul className="pt-4 space-y-6">
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/"
                onClick={handleCloseMobileNav}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/achievements/"
                onClick={handleCloseMobileNav}
              >
                Achievements
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/publications/"
                onClick={handleCloseMobileNav}
              >
                Publications
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/work/"
                onClick={handleCloseMobileNav}
              >
                My Work
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/testimonials/"
                onClick={handleCloseMobileNav}
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/gallery/project-inspection"
                onClick={handleCloseMobileNav}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                activeClassName="drawer-nav__link--active"
                className="drawer-nav__link"
                to="/contact/"
                onClick={handleCloseMobileNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </DrawerBody>
        <DrawerFooter>
          <button aria-label="toggle theme" onClick={handleDarkMode}>
            {theme === 'dark' && (
              <span className="text-white">
                <LightModeIcon />
              </span>
            )}
            {theme === 'light' && (
              <span className="text-black">
                <DarkModeIcon />
              </span>
            )}
          </button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const states = (state: RootState) => {
  return {
    isOpen: state.ui.isShowDrawerNav,
    theme: state.ui.theme,
  };
};

const dispatch = {
  setDrawerNavState: setDrawerNavStateAction,
  setTheme: setThemeAction,
};

export const DrawerNav = connect(states, dispatch)(Component);
