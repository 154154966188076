import React from 'react';

export const FacebookIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    focusable="false"
    height="24"
    viewBox="0 0 48 48"
    width="24"
    {...props}
  >
    <path d="M41.533,0H6.468A6.468,6.468,0,0,0,0,6.468V41.533A6.467,6.467,0,0,0,6.467,48h17.3l.028-17.153H19.335A1.052,1.052,0,0,1,18.282,29.8l-.02-5.53a1.05,1.05,0,0,1,1.05-1.055h4.45V17.872c0-6.2,3.785-9.575,9.315-9.575h4.54A1.053,1.053,0,0,1,38.67,9.35V14a1.053,1.053,0,0,1-1.053,1.052H34.833c-3,0-3.59,1.427-3.59,3.525V23.2h6.61A1.05,1.05,0,0,1,38.9,24.378L38.25,29.92a1.053,1.053,0,0,1-1.045.927H31.272L31.25,48H41.54A6.467,6.467,0,0,0,48,41.533V6.468A6.467,6.467,0,0,0,41.533,0Z" />
  </svg>
);
