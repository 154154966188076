import { useColorMode } from '@chakra-ui/react';
import { RootState } from '@redux/store';
import {
  SetTheme,
  setTheme as setThemeAction,
  ToggleDrawerNavState,
  toggleDrawerNavState as toggleDrawerNavStateAction,
} from '@redux/ui';
import { DarkModeIcon, LightModeIcon, Logo, MenuIcon } from '@svg';
import { Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  basePathname: string;
  setTheme: SetTheme;
  theme: string;
  toggleDrawerNavState: ToggleDrawerNavState;
}

const Component = (props: Props) => {
  //
  const { setTheme, theme, toggleDrawerNavState } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  const handleDarkMode = () => {
    if (colorMode === 'dark') {
      document.documentElement.classList.remove('dark');
      setTheme('light');
      toggleColorMode();
      return;
    }

    if (colorMode === 'light') {
      document.documentElement.classList.add('dark');
      setTheme('dark');
      toggleColorMode();
      return;
    }
  };

  const handleMobileNavState = () => {
    toggleDrawerNavState();
  };

  return (
    <header className="relative px-4 py-6 sm:px-6 lg:px-8 pagewidth">
      <nav>
        <ul className="flex items-center justify-between" role="menubar">
          <li className="flex headerNav:hidden" role="none">
            <button
              aria-label="menu"
              type="button"
              onClick={handleMobileNavState}
            >
              <MenuIcon className="w-6 h-6" />
            </button>
          </li>
          <li className="flex" role="none">
            <Link aria-label="homepage" role="menuitem" to="/">
              <Logo className="w-auto h-8" />
            </Link>
          </li>
          <li role="menuitem">
            <ul className="flex">
              <li className="hidden mr-8 headerNav:flex">
                <ul className="flex items-center space-x-4" role="menu">
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/achievements/"
                    >
                      Achievements
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/publications/"
                    >
                      Publications
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/work/"
                    >
                      My Work
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/testimonials/"
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/gallery/project-inspection"
                    >
                      Gallery
                    </Link>
                  </li>
                  <li role="none">
                    <Link
                      activeClassName="header__link--active"
                      className="header__link"
                      role="menuitem"
                      to="/contact/"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="flex">
                <button aria-label="toggle theme" onClick={handleDarkMode}>
                  {theme === 'dark' && (
                    <span className="text-white">
                      <LightModeIcon />
                    </span>
                  )}
                  {theme === 'light' && (
                    <span className="text-black">
                      <DarkModeIcon />
                    </span>
                  )}
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

const states = (state: RootState) => {
  return {
    theme: state.ui.theme,
  };
};

const dispatch = {
  setTheme: setThemeAction,
  toggleDrawerNavState: toggleDrawerNavStateAction,
};

export const Header = connect(states, dispatch)(Component);
