import React from 'react';

export const WhatsappIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    focusable="false"
    height="24"
    viewBox="0 0 48 48"
    width="24"
    {...props}
  >
    <path
      d="M20,0A20,20,0,0,0,3.81,31.72L1.32,39.15,9,36.7A20,20,0,1,0,20,0Z"
      fill="#6fc062"
    />
    <path
      d="M14.41,10.16c-.38-.93-.68-1-1.26-1l-.68,0a2.87,2.87,0,0,0-2,.72,6.33,6.33,0,0,0-2,4.86c0,2.87,2.1,5.65,2.38,6s4.08,6.37,10,8.8c4.59,1.9,6,1.73,7,1.5,1.53-.32,3.45-1.45,3.93-2.82A5,5,0,0,0,32,25.47c-.14-.25-.53-.39-1.11-.68s-3.45-1.71-4-1.9a1.11,1.11,0,0,0-1.43.44,24.34,24.34,0,0,1-1.57,2.07,1.27,1.27,0,0,1-1.41.22,14.77,14.77,0,0,1-4.69-2.89,17.94,17.94,0,0,1-3.25-4c-.34-.59,0-.93.24-1.25s.58-.62.87-1a3.61,3.61,0,0,0,.65-.91,1.19,1.19,0,0,0-.09-1.08c-.14-.3-1.31-3.17-1.8-4.33Z"
      fill="#ffffff"
    />
  </svg>
);
