interface Props {
  colorMode: 'light' | 'dark';
}

export const Button = {
  baseStyle: (props: Props) => {
    const { colorMode } = props;

    return {
      _disabled: {
        boxShadow: 'none',
        cursor: 'not-allowed',
        opacity: 0.4,
      },
      _focus: {
        boxShadow: 'outline',
      },
      _hover: {
        _disabled: {
          bg: 'initial',
        },
      },
      borderRadius: 'md',
      fontWeight: 'semibold',
      lineHeight: '1.2',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    };
  },

  sizes: {
    lg: {
      fontSize: 'md',
      h: 12,
      minW: 12,
      px: 6,
    },
    md: {
      fontSize: 'sm',
      h: 10,
      minW: 10,
      px: 4,
    },
    sm: {
      fontSize: 'sm',
      h: 8,
      minW: 8,
      px: 3,
    },
    xs: {
      fontSize: 'xs',
      h: 6,
      minW: 6,
      px: 2,
    },
  },

  variants: {
    solid: (props: Props) => {
      const { colorMode } = props;
      return {
        _active: {
          bg: colorMode === 'dark' ? 'indigo.800' : 'indigo.500',
        },
        _disabled: {
          bg: colorMode === 'dark' ? 'gray.600' : 'indigo.700',
        },
        _hover: {
          _disabled: {
            bg: colorMode === 'dark' ? 'gray.600' : 'indigo.700',
          },
          bg: colorMode === 'dark' ? '#3951A5' : 'indigo.700',
        },
        bg: colorMode === 'dark' ? 'indigo.700' : '#3951A5',
        color: 'white',
      };
    },
  },
};
