// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-achievements-tsx": () => import("./../../../src/pages/achievements.tsx" /* webpackChunkName: "component---src-pages-achievements-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-gallery-project-inspection-tsx": () => import("./../../../src/pages/gallery/project-inspection.tsx" /* webpackChunkName: "component---src-pages-gallery-project-inspection-tsx" */),
  "component---src-pages-gallery-training-capacity-building-tsx": () => import("./../../../src/pages/gallery/training-capacity-building.tsx" /* webpackChunkName: "component---src-pages-gallery-training-capacity-building-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-testimonials-denver-international-airport-tsx": () => import("./../../../src/pages/testimonials/denver-international-airport.tsx" /* webpackChunkName: "component---src-pages-testimonials-denver-international-airport-tsx" */),
  "component---src-pages-testimonials-kl-international-airport-tsx": () => import("./../../../src/pages/testimonials/kl-international-airport.tsx" /* webpackChunkName: "component---src-pages-testimonials-kl-international-airport-tsx" */),
  "component---src-pages-testimonials-kl-monorail-project-tsx": () => import("./../../../src/pages/testimonials/kl-monorail-project.tsx" /* webpackChunkName: "component---src-pages-testimonials-kl-monorail-project-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-work-denver-colorado-usa-tsx": () => import("./../../../src/pages/work/denver-colorado-usa.tsx" /* webpackChunkName: "component---src-pages-work-denver-colorado-usa-tsx" */),
  "component---src-pages-work-iom-indonesia-tsx": () => import("./../../../src/pages/work/iom-indonesia.tsx" /* webpackChunkName: "component---src-pages-work-iom-indonesia-tsx" */),
  "component---src-pages-work-iom-kabul-afghanistan-tsx": () => import("./../../../src/pages/work/iom-kabul-afghanistan.tsx" /* webpackChunkName: "component---src-pages-work-iom-kabul-afghanistan-tsx" */),
  "component---src-pages-work-iom-un-indonesia-1-tsx": () => import("./../../../src/pages/work/iom-un-indonesia-1.tsx" /* webpackChunkName: "component---src-pages-work-iom-un-indonesia-1-tsx" */),
  "component---src-pages-work-iom-un-indonesia-2-tsx": () => import("./../../../src/pages/work/iom-un-indonesia-2.tsx" /* webpackChunkName: "component---src-pages-work-iom-un-indonesia-2-tsx" */),
  "component---src-pages-work-kuala-lumpur-malaysia-tsx": () => import("./../../../src/pages/work/kuala-lumpur-malaysia.tsx" /* webpackChunkName: "component---src-pages-work-kuala-lumpur-malaysia-tsx" */),
  "component---src-pages-work-putrajaya-malaysia-tsx": () => import("./../../../src/pages/work/putrajaya-malaysia.tsx" /* webpackChunkName: "component---src-pages-work-putrajaya-malaysia-tsx" */),
  "component---src-pages-work-sepang-malaysia-tsx": () => import("./../../../src/pages/work/sepang-malaysia.tsx" /* webpackChunkName: "component---src-pages-work-sepang-malaysia-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

