import React from 'react';

export const ReliefIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect fill="currentColor" height="24" rx="3" width="24" />
    <g fill="#fff">
      <path d="M10.59,2.05A6.84,6.84,0,0,0,6.78,4,8.07,8.07,0,0,0,4.4,9.43V10l2.83,1.91L10,13.78H6.72V22H17.27V13.8H14l2.82-1.9L19.59,10V9.69a8,8,0,0,0-.84-3.53,7.26,7.26,0,0,0-5.39-4.11A18.43,18.43,0,0,0,10.59,2.05Zm-.23,1.5A10.34,10.34,0,0,0,9.15,7.8l-.06.67-.28-.15A5.06,5.06,0,0,0,8.16,8a2,2,0,0,0-.86-.12A1.91,1.91,0,0,0,6.46,8a5.11,5.11,0,0,0-.57.23l-.2.1,0-.16a7.18,7.18,0,0,1,.49-1.4A6.65,6.65,0,0,1,9.68,3.5a4.86,4.86,0,0,1,.84-.25A1.82,1.82,0,0,1,10.36,3.55ZM14,3.4a6.48,6.48,0,0,1,3.74,3.38,7.61,7.61,0,0,1,.51,1.43l0,.16-.2-.1A4.85,4.85,0,0,0,17.53,8a2,2,0,0,0-.85-.12,2.06,2.06,0,0,0-.86.12,4.89,4.89,0,0,0-.65.29l-.28.14,0-.36a11,11,0,0,0-1.22-4.5C13.41,3.19,13.38,3.21,14,3.4Zm-1.57.48a9.2,9.2,0,0,1,1.24,4.26l0,.3-.25-.13a4.14,4.14,0,0,0-.62-.26A1.88,1.88,0,0,0,12,7.92a2.06,2.06,0,0,0-.86.12,5.21,5.21,0,0,0-.61.27l-.25.13,0-.3a8.36,8.36,0,0,1,1.53-4.62l.19-.2.13.15C12.21,3.55,12.35,3.74,12.45,3.88ZM7.8,9.17A4,4,0,0,1,9,9.83c.16.14.24.28.84,1.47.36.73.65,1.32.64,1.32S6,9.67,6,9.63s.6-.41.93-.5A1.44,1.44,0,0,1,7.8,9.17Zm4.73,0a4,4,0,0,1,1,.55l.09.08-.8,1.6C12.37,12.29,12,13,12,13s-.38-.72-.82-1.6l-.8-1.6.09-.08A3.11,3.11,0,0,1,12,9.07,1.84,1.84,0,0,1,12.53,9.18Zm4.72,0a3,3,0,0,1,.76.43s-4.38,3-4.46,3,.26-.59.62-1.31c.74-1.48.69-1.41,1.37-1.84a2,2,0,0,1,1.2-.39A2.71,2.71,0,0,1,17.25,9.18ZM16.09,17.9v2.93H7.89V15h8.2Z" />
    </g>
  </svg>
);
