/* eslint-disable max-len */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { RootState } from '@redux/store';
import {
  SetDialogState,
  setDialogState as setDialogStateAction,
} from '@redux/ui';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  isOpen: boolean;
  message: string;
  setDialogState: SetDialogState;
  title: string;
}

const Component = ({ isOpen, message, setDialogState, title }: Props) => {
  //
  const handleCloseDialog = () => {
    setDialogState({ isShow: false, message: '', title: '' });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseDialog}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>
        {/* <ModalFooter>Modal Footer</ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

const states = (state: RootState) => {
  return {
    isOpen: state.ui.isShowDialog,
    message: state.ui.dialogMessage,
    title: state.ui.dialogTitle,
  };
};

const dispatch = {
  setDialogState: setDialogStateAction,
};

export const Dialog = connect(states, dispatch)(Component);
