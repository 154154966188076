import { extendTheme } from '@chakra-ui/react';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Input } from './input';

const colors = {
  gray: {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    50: '#F9FAFB',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },

  indigo: {
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    50: '#EEF2FF',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
    800: '#3730A3',
    900: '#312E81',
  },
};

export const theme = extendTheme({
  colors,
  components: { Button, Checkbox, Input },
});
