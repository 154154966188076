import React from 'react';

export const LinkedInIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    focusable="false"
    height="24"
    viewBox="0 0 48 48"
    width="24"
    {...props}
  >
    <path d="M0,0V48H48V0ZM14.93,39.88H7.81V18.45h7.12ZM11.37,15.53h0a3.72,3.72,0,1,1,.1-7.41,3.72,3.72,0,1,1-.05,7.41ZM40.62,39.88H33.49V28.41c0-2.87-1-4.84-3.61-4.84a3.9,3.9,0,0,0-3.65,2.61A4.76,4.76,0,0,0,26,27.91v12H18.87s.09-19.42,0-21.43H26v3A7.08,7.08,0,0,1,32.41,18c4.69,0,8.21,3.06,8.21,9.64Z" />
  </svg>
);
