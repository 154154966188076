import { store as reduxStore } from '@redux/store';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

interface Props {
  element: JSX.Element;
}

export const wrapRootElement = ({ element }: Props) => (
  <>
    <ReduxProvider store={reduxStore}>{element}</ReduxProvider>
  </>
);
