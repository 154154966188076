import xw from 'twin.macro';

interface Props {
  classes?: string;
  error?: string;
  label: string;
  name: string;
  placeholder?: string;
  register: any;
  requiredText?: string;
}

const styles = {
  textarea: xw`
    w-full h-full px-3 py-3 text-base font-semibold text-black placeholder-gray-500 border border-gray-500 rounded-md resize-none dark:border-gray-400 dark:text-white focus:border-indigo-500 focus:outline-none
    dark:focus:shadow-focus-dark focus:shadow-focus-light hover:border-indigo-700 dark:hover:border-indigo-400
  `,
};

export const TextArea = (props: Props) => {
  //
  const { classes, error, label, name, placeholder, register, requiredText } =
    props;

  const labelText = error ? (
    <span className="text-red-700 dark:text-red-400">{error}</span>
  ) : (
    label
  );

  return (
    <div className={`relative flex items-center h-40 ${classes}`}>
      <label
        className="absolute ml-3 text-base text-black -top-7 dark:text-gray-400"
        htmlFor={name}
      >
        {labelText}
      </label>
      <textarea
        id={name}
        {...register(name, { required: requiredText ? requiredText : false })}
        className="background--shaded"
        css={[styles.textarea]}
        placeholder={placeholder}
      />
    </div>
  );
};
