import { Logo } from '@svg';
import { Link } from 'gatsby';
import React from 'react';
import xw from 'twin.macro';

interface Props {
  basePathname: string;
}

const styles = {
  link: xw`
    font-medium text-blue-200 dark:text-gray-400 hover:text-white dark:hover:text-blue-100 focus:text-white dark:focus:text-blue-100
  `,
  'link--active': xw`
    font-medium text-white dark:text-white hover:text-white dark:hover:text-blue-100 focus:text-white dark:focus:text-blue-100
  `,
};

const Component = (props: Props) => {
  //
  const { basePathname: path } = props;

  return (
    <footer className="relative px-6 pb-12 bg-brand-blue dark:bg-gray-900">
      <div className="pagewidth">
        <hr className="h-px mb-6 border-indigo-700 dark:border-gray-400" />
        <div className="grid md:flex md:justify-between">
          <div className="mx-auto mb-6 text-center text-white md:mb-0 md:mx-0 md:flex md:items-end">
            <div className="mb-2 md:mb-0 md:mr-4">
              <div className="flex justify-center text-white">
                <Logo className="w-auto h-8" />
              </div>
            </div>
            <div className="text-xs">
              &copy; {new Date().getFullYear()}. All rights reserved.
            </div>
          </div>
          <nav
            aria-label="Footer"
            className="mx-auto text-sm text-center md:text-base md:mx-0"
          >
            <ul className="flex space-x-6" role="menu">
              <li role="none">
                <Link
                  css={
                    path === 'privacy-policy'
                      ? [styles['link--active']]
                      : [styles.link]
                  }
                  role="menuitem"
                  to="/privacy-policy/"
                >
                  Privacy Policy
                </Link>
              </li>
              <li role="none">
                <Link
                  css={
                    path === 'cookie-policy'
                      ? [styles['link--active']]
                      : [styles.link]
                  }
                  role="menuitem"
                  to="/cookie-policy/"
                >
                  Cookie Policy
                </Link>
              </li>
              <li role="none">
                <Link
                  css={
                    path === 'terms-of-use'
                      ? [styles['link--active']]
                      : [styles.link]
                  }
                  role="menuitem"
                  to="/terms-of-use/"
                >
                  Terms <span className="whitespace-nowrap">of Use</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export const Footer = Component;
