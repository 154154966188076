/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

interface States {
  dialogMessage: string;
  dialogTitle: string;
  isShowDialog: boolean;
  isShowDrawerNav: boolean;
  theme: 'light' | 'dark';
}

const initialState: States = {
  dialogMessage: '',
  dialogTitle: '',
  isShowDialog: false,
  isShowDrawerNav: false,
  theme: 'light',
};

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    setDialogState: (state, action) => {
      const { payload } = action;
      const { isShow, message, title } = payload;
      state.isShowDialog = isShow;
      state.dialogTitle = title;
      state.dialogMessage = message;
    },
    setDrawerNavState: (state, action) => {
      const { payload } = action;
      state.isShowDrawerNav = payload;
    },
    setTheme: (state, action) => {
      const { payload } = action;
      state.theme = payload;
    },
    toggleDialogState: (state) => {
      state.isShowDialog = !state.isShowDialog;
    },
    toggleDrawerNavState: (state) => {
      state.isShowDrawerNav = !state.isShowDrawerNav;
    },
  },
});

export const {
  setDialogState,
  setDrawerNavState,
  setTheme,
  toggleDialogState,
  toggleDrawerNavState,
} = uiSlice.actions;

export default uiSlice.reducer;

export type SetDrawerNavState = (x: boolean) => void;

export type ToggleDrawerNavState = () => void;

export type SetDialogState = ({
  isShow,
  message,
  title,
}: {
  isShow: boolean;
  message: string;
  title: string;
}) => void;

export type ToggleDialogState = () => void;

export type SetTheme = (x: string) => void;
