interface Props {
  colorMode: 'light' | 'dark';
}

export const Checkbox = {
  baseStyle: (props: Props) => {
    const { colorMode } = props;

    return {
      control: {
        _checked: {
          _disabled: {
            bg: colorMode === 'dark' ? 'green' : 'red',
            borderColor: colorMode === 'dark' ? 'green' : 'red',
            color: colorMode === 'dark' ? 'white' : 'red',
          },
          _hover: {
            bg: colorMode === 'dark' ? 'white' : 'black',
            borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',
          },
          bg: colorMode === 'dark' ? 'white' : 'black',

          borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.400',

          color: colorMode === 'dark' ? 'black' : 'white',
        },
        background: colorMode === 'dark' ? 'gray.800' : 'gray.100',
        border: '1px solid',
        borderColor: colorMode === 'dark' ? 'gray.400' : 'gray.500',

        color: 'white',
      },
    };
  },
};
