import React from 'react';

export const TwitterIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    focusable="false"
    height="24"
    viewBox="0 0 48 48"
    width="24"
    {...props}
  >
    <path d="M48,9.11a19.22,19.22,0,0,1-5.66,1.55,9.78,9.78,0,0,0,4.33-5.44,20.11,20.11,0,0,1-6.25,2.39,9.85,9.85,0,0,0-16.78,9A28,28,0,0,1,3.34,6.3a9.75,9.75,0,0,0-1.33,5,9.85,9.85,0,0,0,4.38,8.2,9.88,9.88,0,0,1-4.46-1.24v.13A9.84,9.84,0,0,0,9.83,28a9.71,9.71,0,0,1-2.6.35,9.44,9.44,0,0,1-1.85-.18A9.85,9.85,0,0,0,14.58,35,19.78,19.78,0,0,1,2.35,39.22,20.18,20.18,0,0,1,0,39.08,27.9,27.9,0,0,0,15.1,43.5c18.11,0,28-15,28-28,0-.43,0-.86,0-1.28A20,20,0,0,0,48,9.11" />
  </svg>
);
