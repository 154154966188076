interface Props {
  colorMode: 'light' | 'dark';
}

export const Input = {
  baseStyle: (props: Props) => {
    const { colorMode } = props;

    return {
      field: {
        _autofill: {
          // 'text-fill-color': colorMode === 'dark' ? 'white' : 'black',
          // transition: 'background-color 5000s ease-in-out 0s',
        },
        fontWeight: 600,
      },
    };
  },

  sizes: {
    lg: {
      field: {
        fontSize: 'md',
      },
    },
  },

  variants: {
    outline: (props: Props) => {
      const { colorMode } = props;
      return {
        field: {
          _focus: {
            borderColor: colorMode === 'dark' ? 'indigo.400' : 'indigo.700',
            boxShadow:
              colorMode === 'dark' ? '0 0 0 1px #818CF8' : '0 0 0 1px #4338CA',
            zIndex: 1,
          },
          _hover: {
            borderColor: colorMode === 'dark' ? 'indigo.400' : 'indigo.700',
          },
          _placeholder: {
            color: colorMode === 'dark' ? 'gray.500' : 'gray.400',
          },
          bg: colorMode === 'dark' ? 'gray.800' : 'gray.100',
          border: '1px solid',
          borderColor: colorMode === 'dark' ? 'gray.400' : 'gray.500',
        },
      };
    },
  },
};
