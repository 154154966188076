import React from 'react';

export const MentoringIcon = (props: any) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect fill="currentColor" height="24" rx="3" width="24" />
    <g fill="#fff">
      <path d="M8.92,2.07A3.65,3.65,0,0,0,6.47,3.94a3.78,3.78,0,0,0-.33,1,4.49,4.49,0,0,0,0,1.27c.07.29.06.29-.2.29A2.69,2.69,0,0,0,3.55,8,2.65,2.65,0,0,0,5.1,11.64l.2.07,0,.52c0,.43,0,.55.1.66a.61.61,0,0,0,.77.23c.06,0,.45-.34.86-.69l.76-.63H9c.68,0,1.39,0,1.6,0a2.78,2.78,0,0,0,1.13-.36,3.31,3.31,0,0,0,.89-.9,3,3,0,0,0,.35-1c.05-.47,0-.44.24-.44s.19,0,1.34,1c.64.53,1.19,1,1.24,1a.6.6,0,0,0,.76-.16c.08-.11.09-.17.11-1l0-.85L17,9a3.62,3.62,0,0,0,2.21-1.74,3.27,3.27,0,0,0,.42-1.9,3.33,3.33,0,0,0-1-2.25,3.67,3.67,0,0,0-1.7-1L16.67,2H13A29,29,0,0,0,8.92,2.07Zm7.7,1.18a2.55,2.55,0,0,1,1.47,1,2.31,2.31,0,0,1,.37,1.29,2.27,2.27,0,0,1-.68,1.67,2.47,2.47,0,0,1-1.57.71c-.58.05-.7.2-.7.89a2.59,2.59,0,0,1,0,.44l-.73-.6C14.37,8.34,14,8,13.92,8a8.87,8.87,0,0,0-2.31-.07c-2.44,0-2.49,0-3-.27A2.38,2.38,0,0,1,7.33,5.07,2.45,2.45,0,0,1,9.05,3.26c.19,0,.86,0,3.8,0C15.28,3.2,16.48,3.21,16.62,3.25ZM7,7.94A3.77,3.77,0,0,0,8.7,9a6.2,6.2,0,0,0,1.73.1l1.42,0,0,.2A1.53,1.53,0,0,1,11,10.45l-.25.12-1.64,0c-1.88,0-1.68,0-2.21.43l-.33.27,0-.16c-.06-.33-.25-.49-.66-.55a1.4,1.4,0,0,1-.93-.42,1.34,1.34,0,0,1-.42-.79A1.45,1.45,0,0,1,5.18,7.9a1.62,1.62,0,0,1,1-.2h.57Z" />
      <path d="M17,12.5a2.69,2.69,0,0,0-1.73,4.34l.19.23-.19.1a5.06,5.06,0,0,0-1.8,1.57A1.78,1.78,0,0,0,13.08,20a1.57,1.57,0,0,0,.28,1.07,2,2,0,0,0,1.24.89c.34.08,5.22.08,5.6,0a2.05,2.05,0,0,0,1.47-1.45,2.21,2.21,0,0,0-.07-1.22,4.86,4.86,0,0,0-2.09-2.1l-.21-.1.16-.19A2.74,2.74,0,0,0,20,14.61a2.94,2.94,0,0,0-.55-1.14A2.75,2.75,0,0,0,17,12.5Zm1,1.32a1.48,1.48,0,1,1-1-.09A1.59,1.59,0,0,1,18,13.82Zm0,4.11a3.77,3.77,0,0,1,1.38.59,3.09,3.09,0,0,1,1.1,1.36.81.81,0,0,1-.29.74c-.2.16-.2.16-2.86.16a11.13,11.13,0,0,1-2.68-.07.75.75,0,0,1-.43-.77.72.72,0,0,1,.17-.47,3.82,3.82,0,0,1,1.34-1.2A3.51,3.51,0,0,1,18.05,17.93Z" />
      <path d="M5.25,14.38a2.28,2.28,0,0,0-1.6,1.52,2.8,2.8,0,0,0,0,1.19,3,3,0,0,0,.35.73.31.31,0,0,1,.08.17l-.23.14a3.48,3.48,0,0,0-1.49,1.73,1.72,1.72,0,0,0,1,2A8.47,8.47,0,0,0,5.7,22a9.85,9.85,0,0,0,2.39-.07A1.65,1.65,0,0,0,9.3,20.3a2.06,2.06,0,0,0-.78-1.55,5.13,5.13,0,0,0-.68-.57L7.51,18s0-.05.06-.1A2.15,2.15,0,0,0,8,16.54,2.07,2.07,0,0,0,7.35,15,2.12,2.12,0,0,0,5.25,14.38Zm1,1.22a1,1,0,1,1-1.39,1.34.8.8,0,0,1-.07-.48A.73.73,0,0,1,4.93,16a1.26,1.26,0,0,1,.56-.44A1.35,1.35,0,0,1,6.24,15.6Zm.36,3.3a2.87,2.87,0,0,1,1,.57,2.19,2.19,0,0,1,.54.74.56.56,0,0,1-.18.48l-.12.09h-2c-2.19,0-2.15,0-2.28-.24s0-.54.38-1A2.63,2.63,0,0,1,6.6,18.9Z" />
    </g>
  </svg>
);
