import { Dialog } from '@components/dialog';
import { DrawerNav } from '@components/drawerNav';
import { Footer } from '@components/footer';
import { Header } from '@components/header';
import { SetTheme, setTheme as setThemeAction } from '@redux/ui';
import { useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import '@styles/base.css';

interface Props {
  basePathname: string;
  children: JSX.Element;
  fullPath: string;
  setTheme: SetTheme;
}

const Component = ({ basePathname, children, setTheme }: Props) => {
  //
  useEffect(() => {
    if (localStorage['chakra-ui-color-mode']) {
      const mode = localStorage['chakra-ui-color-mode'];
      if (mode === 'light') {
        setTheme('light');
        document.documentElement.classList.remove('dark');
        return;
      }
      if (mode === 'dark') {
        setTheme('dark');
        document.documentElement.classList.add('dark');
        return;
      }
    }
    if (!localStorage['chakra-ui-color-mode']) {
      setTheme('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('chakra-ui-color-mode', 'light');
      return;
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-h-screen">
        <Dialog />
        <DrawerNav basePathname={basePathname} />
        <Header basePathname={basePathname} />
        <main className="flex-grow pagewidth">{children}</main>
        <Footer basePathname={basePathname} />
      </div>
    </>
  );
};

const dispatch = {
  setTheme: setThemeAction,
};

export const Layout = connect(null, dispatch)(Component);
