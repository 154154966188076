import React from 'react';

export const Logo = (props: any) => (
  <svg
    aria-hidden="true"
    fill="currentColor"
    focusable="false"
    viewBox="0 0 509.79 100"
    {...props}
  >
    <polygon points="258.22 4.74 489.32 50.76 509.79 50.76 254.89 0 0 50.76 27.11 50.76 258.22 4.74" />
    <path d="M81.91,75.39v4.47H92.07V87H81.91v4.59H94.26v8H73.51v-32H94.26v7.9Z" />
    <path d="M117.52,99.5,109,85.6V99.5h-8.5l0-32h12.15c6.91,0,11.86,4.67,11.86,10.41a9.65,9.65,0,0,1-5.54,8.75l8.58,12.85Zm-8.6-18,3.47,0a3.22,3.22,0,1,0,0-6.43h-3.47Z" />
    <path d="M141.83,83.52A16.61,16.61,0,0,1,158.28,67a16,16,0,0,1,11,4.85l-2.11,2.84a12.78,12.78,0,0,0-9-4,12.79,12.79,0,1,0,9.05,21.6l2.09,2.9a15.22,15.22,0,0,1-11,4.71A16.55,16.55,0,0,1,141.83,83.52Z" />
    <path d="M170.6,83.52A16.46,16.46,0,1,1,187,99.92,16.52,16.52,0,0,1,170.6,83.52Zm29.25,0A12.8,12.8,0,1,0,187,96.27,12.85,12.85,0,0,0,199.85,83.5Z" />
    <path d="M234.3,67.41V100h-.18L212.18,75.63V99.54h-3.62V67h.2l21.93,24.35V67.41Z" />
    <path d="M239,93.78l2.78-2a9.27,9.27,0,0,0,8.12,4.57c4.19,0,7.61-2.27,7.61-5.43,0-3.59-4.59-5.32-8.68-6.87-3.93-1.53-8.32-3.45-8.32-8.4S245,67,250.58,67c4.74,0,8,2.6,9.17,5.76L257,74.6a7,7,0,0,0-6.49-4c-3.44,0-6.38,2.26-6.38,5.12,0,3.1,3.52,4.23,7.65,5.82,4.8,1.95,9.35,4.17,9.35,9.31,0,5.53-5.1,9.12-11.26,9.12C244.64,99.94,240.55,97.2,239,93.78Z" />
    <path d="M265.54,87.07V67.49h3.64V87.05c0,5.38,3.49,9.3,8.22,9.3s8.22-3.94,8.22-9.3V67.49h3.64V87.07c0,7.47-5.05,12.93-11.86,12.93S265.54,94.54,265.54,87.07Z" />
    <path d="M315,95.91V99.5H295.66v-32h3.62V95.91Z" />
    <path d="M333.23,71.06H324V99.5h-3.62V71.06H311V67.49h22.2Z" />
    <path d="M354.52,94.24H337L334.7,99.5h-3.92L345.58,67h.4L360.79,99.5h-3.93Zm-1.39-3.14L345.8,74.64,338.45,91.1Z" />
    <path d="M390.39,67.41V100h-.17l-22-24.37V99.54h-3.61V67h.19l21.93,24.35V67.41Z" />
    <path d="M395.45,83.52A16.61,16.61,0,0,1,411.89,67a16,16,0,0,1,11,4.85l-2.11,2.84a12.79,12.79,0,0,0-9-4,12.79,12.79,0,1,0,9.06,21.6l2.09,2.9a15.25,15.25,0,0,1-11,4.71A16.55,16.55,0,0,1,395.45,83.52Z" />
    <path d="M435.72,85.9l-11.3-18.41h4.05l9,14.38,9.06-14.38h4.07L439.31,85.9V99.5h-3.59Z" />
  </svg>
);
